import {css, Global} from '@emotion/react';
import React from 'react';

const globalStyle = css`
  @font-face {
    font-family: Helvetica Neue;
    src: url('/HelveticaNeue.ttf');
  }

  html, body {
    margin: 0;
    padding: 0;

    font: 13px Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-text-size-adjust: 100%;
  }

  .dot {
    width: 12px !important;
    height: 12px !important;
  }

  .carousel > button {
    background: rgba(0, 0, 0, 0.2) !important;
  }
`;

export const GlobalStyle: React.FC = () => {
    return <Global styles={globalStyle}/>;
};